/* eslint-disable */

import Vue from 'vue';
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue';
import ChevronUpIcon from 'vue-material-design-icons/ChevronUp.vue';
import ArrowUpIcon from 'vue-material-design-icons/ArrowUp.vue';
import CheckCircleIcon from 'vue-material-design-icons/CheckCircle.vue';
import SegmentIcon from 'vue-material-design-icons/Segment.vue';
import CloseIcon from 'vue-material-design-icons/Close.vue';

const components = {
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowUpIcon,
  CheckCircleIcon,
  SegmentIcon,
  CloseIcon,
};

Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component);
});
